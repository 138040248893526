<style lang="scss" scoped>
@use "assets/scss/default.scss";
</style>

<template>
  <div>
    <Header></Header>
    <div id="wrapper">
      <Navbar :navLinks="navLinks"></Navbar>
      <main>
        <slot />
      </main>
    </div>
  </div>
</template>

<script setup>
const ui = {
  base: "h-full",
  padding: "",
  constrained: ""
};

const navLinks = [
  { url: "/management", name: "Administration", icon: "isax isax-element-4" },
  { url: "/management/accounts", name: "Comptes client", icon: "isax isax-buildings4" },
  { url: "/management/orders", name: "Commandes", icon: "isax isax-box" },
  { url: "/management/config", name: "Configurations", icon: "isax isax-code-1" },
  { url: "/management/inspector", name: "Inspecteur", icon: "isax isax-box-search" },
  { url: "/management/status", name: "Statuts", icon: "isax isax-archive" },
];
</script>

<script>


export default {
  name: "ManagementLayout"
};
</script>
